<template>
  <v-app id="app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
  <metainfo>
  </metainfo>
  <vue-cookie-accept-decline :debug="false" :disableDecline="false" :showPostponeButton="false"
    @clicked-accept="cookieClickedAccept" @clicked-decline="cookieClickedDecline"
    @clicked-postpone="cookieClickedPostpone" @removed-cookie="cookieRemovedCookie" @status="cookieStatus"
    elementId="cookies_modal" position="bottom-left" ref="myPanel1" transitionName="slideFromBottom" type="floating">
    <template #postponeContent>&times;</template>

    <template #message>
      <p class="cookies_modal_title mb-0">We use cookies </p>
      <p class="cookies_modal_subtitle mb-0">We use Google Analytics to analyze website traffic </p>
      <v-row class="ma-0">
        <v-col cols="6" class="pa-0">
          <v-checkbox v-model="selected_cookies" disabled color="#CF9634" base-color="#fff" class="text-white"
            label="Necessary" value="1" hide-details density="compact"></v-checkbox>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-checkbox v-model="selected_cookies" color="#CF9634" base-color="#fff" class="text-white"
            label="Preferences" value="2" hide-details density="compact"></v-checkbox>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-checkbox v-model="selected_cookies" color="#CF9634" base-color="#fff" class="text-white" label="Statistics"
            value="3" hide-details density="compact"></v-checkbox>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-checkbox v-model="selected_cookies" color="#CF9634" base-color="#fff" class="text-white" label="Marketing"
            value="4" hide-details density="compact"></v-checkbox>
        </v-col>
      </v-row>
    </template>

    <template #acceptContent>ACCEPT</template>

    <template #declineContent>DECLINE </template>
  </vue-cookie-accept-decline>
</template>

<script>
import { useMeta } from 'vue-meta'
import { useLocale } from "vuetify";

export default {
  name: 'App',
  data: () => ({
    selected_cookies: ["1", "2", "3", "4"],
  }),
  setup() {
    const locale = useLocale();
    let data = {
      en: {
        title: "Premier VIP Services by GreenWorld: Chauffeurs, Private Jets, Luxury Yachts, and More",
        description: "Experience unparalleled luxury with Green World. From professional chauffeurs and private jet flights to luxurious yachts and VIP transfers, we offer exceptional services tailored to your needs. Over 375 satisfied clients across Europe trust us for our commitment to excellence.",
      },
      de: {
        title: "GreenWorld - Erstklassige VIP-Dienstleistungen: Chauffeure, Privatjets, Luxusyachten und mehr",
        description: "Erleben Sie unvergleichlichen Luxus mit Green World. Von professionellen Chauffeuren und Privatjet-Flügen bis hin zu luxuriösen Yachten und VIP-Transfers bieten wir außergewöhnliche Dienstleistungen, die auf Ihre Bedürfnisse zugeschnitten sind. Über 375 zufriedene Kunden in ganz Europa vertrauen auf unser Engagement für Exzellenz.",
      },
    };
    useMeta({
      htmlAttrs: {
        lang: locale.current.value,
      },
      title: data[locale.current.value].title,
      description: data[locale.current.value].description,
      meta: [
        { vmid: 'description', name: 'description', content: data[locale.current.value].description }
      ]
    })

    /* void 0 === window._axcb && (window._axcb = []);
    window._axcb.push(function (sdk) {
      sdk.on("*", (event, payload) => {
        if (event === 'cookies:complete') {
          console.log("vvv");
        }
      })
    }) */
  },
  methods: {
    cookieStatus(status) {
      this.status = status;
      if (status == "accept") {
        this.selected_cookies = localStorage.getItem("vue-cookie-accept-decline-cookies_modal_select") ?? [];

        if (this.selected_cookies.includes("2")) {
          window.gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
          })
        }

        if (this.selected_cookies.includes("3")) {
          window.gtag('consent', 'update', {
            'analytics_storage': 'granted'
          })
        }

        if (this.selected_cookies.includes("4")) {
          window.gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_storage': 'granted',
          })
        }
        this.$gtag.optIn();
      } else {
        this.$gtag.optOut();
      }
    },
    cookieClickedAccept() {
      if (this.selected_cookies.includes("2")) {
        window.gtag('consent', 'update', {
          'ad_user_data': 'granted',
          'ad_personalization': 'granted',
        })
      }

      if (this.selected_cookies.includes("3")) {
        window.gtag('consent', 'update', {
          'analytics_storage': 'granted'
        })
      }

      if (this.selected_cookies.includes("4")) {
        window.gtag('consent', 'update', {
          'ad_user_data': 'granted',
          'ad_storage': 'granted',
        })
      }
      localStorage.setItem("vue-cookie-accept-decline-cookies_modal_select", this.selected_cookies)
      this.status = this.selected_cookies;
      this.$gtag.optIn();
    },
    cookieClickedDecline() {
      this.status = [];
    },
    cookieClickedPostpone() {
      console.log('here in postpone');
      this.status = 'postpone';
    },
    cookieRemovedCookie() {
      this.status = null;
      this.$refs.myPanel1.init();
    },
    removeCookie() {
      console.log('Cookie removed');
      this.$refs.myPanel1.removeCookie();
    },
  }
}
</script>
<style>
@import url("vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css");

#cookies_modal .cookie__floating__content {
  margin-bottom: 0px;
  max-height: unset;
}

#cookies_modal .cookie__floating__buttons__button--decline {
  background: transparent !important;
  color: #3d3d3d;
  border: unset;
  font-weight: 400;
  transition: .3s;
}

#cookies_modal .cookie__floating__buttons__button--decline:hover {
  color: #f44336;
  background: rgba(255, 255, 255, 0.1) !important;
}

#cookies_modal .cookie__floating__buttons__button--accept {
  background: transparent !important;
  color: #CF9634;
  border: unset;
  font-weight: 400;
  transition: .3s;
}

#cookies_modal .cookie__floating__buttons__button--accept:hover {
  color: #4caf50;
  background: rgba(255, 255, 255, 0.1) !important;
}

#cookies_modal {
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;

  box-shadow: unset;
}

.cookies_modal_title {
  color: #FFFFFF;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: -0.02rem;
}

.cookies_modal_subtitle {
  color: #737475;
  font-size: 1rem;
  font-weight: 400;
}
</style>
<style lang="scss">
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6,
.text-title,
.fontRaleway {
  font-family: "Raleway", sans-serif !important;
}

html {
  font-size: 15px !important;
  //scroll-behavior: smooth;
  scroll-padding-top: 100px;
  font-family: "Raleway", sans-serif;
}

html,
#app {
  background: #E6E8EB;
  overflow-x: hidden;
}

@media (min-width: 1640px) {
  .v-container {
    max-width: 1600px !important;
  }
}

@media screen and (max-width: 321px) {
  #app {
    zoom: .85;
  }
}

@font-face {
  font-family: "SFProDisplayLight";
  src: url("./assets/fonts/SFProDisplay-Light.ttf");
}

@font-face {
  font-family: "SFProDisplayRegular";
  src: url("./assets/fonts/SFProDisplay-Regular.ttf");
}

@font-face {
  font-family: "SFProDisplaySemiBold";
  src: url("./assets/fonts/SFProDisplay-SemiBold.ttf");
}

.fontSFProL {
  font-family: "SFProDisplayLight" !important;
}

.fontSFProR {
  font-family: "SFProDisplayRegular" !important;
}

.fontSFProB {
  font-family: "SFProDisplaySemiBold" !important;
}
</style>
<style>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
</style>




<!-- <a href="javascript:openAxeptioCookies()">
      Click here to update your cookies settings
    </a>
    void 0 === window._axcb && (window._axcb = []);
window._axcb.push(function(sdk) {
  sdk.on("*", (event, payload) => {
    if (event === 'cookies:complete') {
      /* Handle consent here */
    }
  })
}) -->